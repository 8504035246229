import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from 'config';
import './styles/Header.scss';

class Header extends Component {
  openMaps = (e) => {
    const baseUrl = `maps.google.com/?q=${config.CONTACT_ADDRESS}`;
    const platforms = ['iPhone', 'iPad'];
    const mobilePlatform = platforms.find(p => {
      return navigator.platform.indexOf(p) > 0;
    });

    const url = (mobilePlatform)
      ? 'maps://' + baseUrl
      : 'https://' + baseUrl;

    window.open(url, '_blank');
  }

  openPhone = (e) => {
    console.log(e)
  }

  render() { 
    return (
      <header className="page-head">
        <section className="main-head-top">
          <h1>Beaver Global Autos</h1>

          <div className="main-head-contact">
            <p aria-label="Phone">
              <a href={'tel:' + config.CONTACT_PHONE} tabIndex={2}>{config.CONTACT_PHONE}</a>
            </p>

            <p 
              aria-label="Address" 
              onClick={this.openMaps}
              tabIndex={3}
              role="button">
              {config.CONTACT_ADDRESS}</p>
          </div>
        </section>

        <section className="main-head-bottom">
          <nav>
            <Link to="/" className="btn btn-light">Home</Link>
            <Link to="/" className="btn btn-light">About us</Link>
            <Link to="/" className="btn btn-light">Contact us</Link>
          </nav>
        </section>
      </header>
    );
  }
}
 
export default Header;