import React, { Component } from 'react';

class ContactForm extends Component {
  state = {  }
  render() { 
    return (
      <form>
        <label>
          <h4>First name</h4>
          <input type="text" className="form-control" required />
        </label>
      </form>
    );
  }
}
 
export default ContactForm;