import React, { Component } from 'react';
import Header from 'components/Header';

class Page extends Component {
  componentDidMount() {
    const baseTitle = 'Beaver Global Autos';
    const title = this.props.title 
      ? `${this.props.title} | ${baseTitle}`
      : baseTitle;

    document.title = title;
    window.scrollTo(0, 0);
  }

  render() { 
    return <>
      <Header />
      <main className="page-content">{this.props.children}</main>
    </>;
  }
}
 
export default Page;
