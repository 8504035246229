import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from 'routes/HomePage';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/App.scss';

function App() {
  return (
    <Switch>
      <Route exact path='/' component={HomePage} />
    </Switch>
  );
}

export default App;
