import React, { Component } from 'react';
import Page from 'components/Page';
import ContactForm from 'components/ContactForm';
import './styles/HomePage.scss';

class HomePage extends Component {
  render() { 
    return (
      <Page>
        <section className="welcome-box">
          <h2>Take the next step into your new car</h2>
        </section>

        <section className="main-contact-form">
          <h3>Get in contact with us</h3>
          
          <ContactForm />
        </section>
      </Page>
    );
  }
}
 
export default HomePage;